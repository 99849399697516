import React from 'react';
import {useLocation} from 'react-router-dom'
import "./ReporteConstancia.css"
const ReporteConstancia = () => {

    let location = useLocation();
    let myConstancia = location.state.constancia

    return (
        <>

    
        <div className='content'>

        {myConstancia.constancia != null && (
            <div>
           <img  src="./assets/img/encabezado.png" alt="" />
            <div className='textoNegrita textoCentrado'>
            <div >BRIGHTON CENTRO DE FORMACIÓN EN INGLÉS</div>
       <div >INSTITUCIÓN DE EDUCACIÓN PARA EL TRABAJO Y DESARROLLO HUMANO</div>
       <div >RESOLUCIÓN DE LICENCIA DE FUNCIONAMIENTO Y REGISTRO DE PROGRAMAS DE EFICIENCIA EN INGLES</div>
       <div >No. 1832-27-07-2015 EMANADA DE LA SECRETARIA DE EDUCACIÓN DE PASTO- NARIÑO</div>
       </div>

       <div className='textoCentrado subtitulo'>CONSTANCIA</div>


     
       <div className='texto' dangerouslySetInnerHTML={{ __html: myConstancia.constancia }}></div>     

       <br />


       <div className='texto'>
       <img  src="./assets/img/sellofirma.png" alt="" />
       <div>PAOLA ANDREA NARVAEZ BENAVIDES</div>
<div> SECRETARIA ACADEMICA</div>

        </div>  

        </div>
)}

       <div class="pagebreak"> </div>


       <img  src="./assets/img/encabezado.png" alt="" />
       <div className='textoNegrita textoCentrado'>  
        <div  >RESOLUCIÓN DE LICENCIA DE FUNCIONAMIENTO Y REGISTRO DE PROGRAMAS DE EFICIENCIA EN INGLES</div>
       <div>No. 1832-27-07-2015 EMANADA DE LA SECRETARIA DE EDUCACIÓN DE PASTO- NARIÑO</div>
       </div>

       <div className="textoCentrado subtitulo">Expide el siguiente certificado de formación académica a:</div>


       <div className='texto' dangerouslySetInnerHTML={{ __html: myConstancia.certificado }}></div>       
          
        </div>
        </>


    );
};

export default ReporteConstancia;