import React, {useState, useEffect} from 'react';

import { Breadcrumb, Table, Button, Spin, Input, Modal, message, Form, Select } from "antd";
import MyLayout from "../../components/MyLayout";
import { Content } from "antd/lib/layout/layout";
import cursoService from "../../services/curso.service";
import moment from 'moment';
import matriculaService from "../../services/matricula.service";
import {EditOutlined,EyeInvisibleOutlined,DeleteOutlined,CheckCircleOutlined,CalendarOutlined, SearchOutlined} from '@ant-design/icons';
import { useAuth } from '../../context/auth.context';
import calendarioService from '../../services/calendario.service';
import tarifaService from '../../services/tarifa.service';

const MatriculaModal = (props) => {
    const [cursos, setCursos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [horario,setHorario] = useState()
    const [viewModalOpciones,setViewModalOpciones] = useState(false)
    const {user,role} = useAuth()
    const [cursoSelected, setCursoSelected] = useState();
    const [clases,setClases] = useState()

    const [tarifaCurso,setTarifaCurso] = useState()
    const [tarifa24,setTarifa24] = useState()
    const [tarifa20,setTarifa20] = useState()
    const [valorCobrar,setValorCobrar] = useState(0)
    const [cursoConsulta, setCursoConsulta] = useState();
    const [viewValorMatricula,setViewValorMatricula] = useState(true)

  const empresa_id = user.empresa_id
  const usuario_id = user.id;

    const estudiante_id = props.estudiante_id
    const tipo = props.tipo

    const [formOpciones] = Form.useForm();

    const validarSiCiclo = (value) =>{
     
      if(value == "No"){
        setViewValorMatricula(false)
      } else{
        setViewValorMatricula(true)
      }
    }

    const columns = [

        {
            title: "Codigo",
            dataIndex: "id",
            key: "id",
            // render: (text) => <a>{text}</a>,
          },

          {
            title: "Fecha",
            render: (record) => {
                let myFechaInicial = moment(record.fechaInicial).format("DD/MM/YYYY"); 
                let myFechaFinal = moment(record.fechaFinal).format("DD/MM/YYYY "); 
                return ( <div><div>Desde: {myFechaInicial}</div> <div>Hasta: {myFechaFinal}</div></div> )
            }
          },


          {
            title: "Curso/Nivel",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Curso' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = record.nivel + " " + record.ciclo
              return nombre.toLowerCase().includes(value.toLowerCase())
            },

            render: (record) => 
            <>
           <div>
            {/* <div>{record.curso} -</div> */}
            <div>{record.nivel} -</div>
            <div>{record.ciclo} </div>
            </div>
            {record.estado == "Activo" && (
             <div className='finaliza'>Finaliza  {moment(record.fechaFinal).endOf('day').fromNow()}</div>
             )
            }

          {record.estado == "Finalizado" && (
             <div className='finaliza'>Finalizó  {moment(record.fechaFinal).endOf('day').fromNow()}</div>
             )
            }
           
            </>
          },

          {
            title: "Jornada",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Jornada' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = record.jornada
              return nombre.toLowerCase().includes(value.toLowerCase())
            },
            
            key: "jornada",
            render: (record) => <div>{record.jornada}</div>
          },


          {
            title: "Docente",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
              return <Input autoFocus placeholder='Ingrese Nombre de docente' 
              value = {selectedKeys[0]} 
              onChange={(e)=>{
                setSelectedKeys(e.target.value?[e.target.value]:[])
               confirm({closeDropdown:false})
              }}
              onPressEnter={()=>{
                confirm()
              }} 
              
              onBlur={()=>{
                confirm()
              }} >
              
              </Input>
            },
            filterIcon:()=>{
              return <SearchOutlined />
            },
            onFilter:(value,record)=>{
              let nombre = record.Docente.primerNombre + " " + record.Docente.primerApellido
              return nombre.toLowerCase().includes(value.toLowerCase())
            },
            render: (record) => <div>{record.Docente.primerNombre} {record.Docente.primerApellido} </div>,
          },
        

          {
            title: "Estado",
            dataIndex: "estado",
            key: "estado",
            // render: (text) => <a>{text}</a>,
          },
       
         

          {
            title: "Opciones",
           
            key: "action",
            render: (record) => <Button type='primary' onClick={()=>{verOpciones(record)}}>Matricular</Button>,
          },
      ];


    useEffect(() => {
        listCursos()
       
      }, []);

    
      const listTarifas = (curso) => {
        setLoading(true);
        
      };

      const listCursos = () => {
        setLoading(true);
        cursoService
          .getForainEstadoAll(empresa_id,"Activo")
          .then((response) => {
            let filterCursos = response.data;
            console.log(response.data);
            console.log(tipo)
            // filterCursos = response.data.filter(
            //   (item) => item.estado == "Activo" && item.modo == tipo
            // );
            setCursos(filterCursos);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const handleCancel = () => {
        props.updateVisible(false);
      };

      const cancelOpciones = () => {
        setViewModalOpciones(false);
      };

      const matricular = (values) =>{
        console.log(values)
props.updateLoading(true);
        setLoading(true)
        setViewModalOpciones(false)
        let form = {}
        form.curso_id = cursoSelected.id
        form.estudiante_id = estudiante_id
        form.empresa_id = empresa_id;
        form.usuario_id = usuario_id;
        form.matricula = values.matricula
        form.inscripcion = values.inscripcion
        form.valor_matricula = values.valor_matricula
        form.createdAt = values.createdAt + " 23:59:59"
        matriculaService
          .create(form)
          .then((response) => {
            console.log(response.data);
            props.updateLoading(false);
            props.updateVisible(false);
            setLoading(false)
            props.updateListEstudiante();
            props.updateListObligaciones();
            message.success("Guardado Correctamente");
          })
          .catch((e) => {
            props.updateLoading(false);
            setLoading(false)
            message.error("No se ha podido guardar");
            console.log(e);
          });
      }

    

      const verOpciones = (curso) =>{

        

        calendarioService.getForain(curso.id).then((res) => {
          let horario = res.data
          setClases(null)
        setHorario(null)
      
        setViewModalOpciones(true)
        setCursoSelected(curso)

    


        let momentInicio = moment(curso.horaInicial,"HH:mm:ss")
        let momentFinal = moment(curso.horaFinal,"HH:mm:ss")

        let diferenciaHoras = momentFinal.diff(momentInicio,'hours',true)



        let hoy = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        let fechaCurso = moment(curso.fechaFinal).format('YYYY-MM-DD 23:59:59')
        let contador = 0

        for(let item of horario){
          let fechaHorario = moment(item.start).format('YYYY-MM-DD 23:59:59')
          let diferencia = moment(fechaHorario).diff(hoy,'days');
          if(diferencia < 0) {
            contador ++
          }
        }

       
        

        setClases("Han pasado " + contador + " clases desde el inicio del curso")

        tarifaService
          .getForain(empresa_id)
          .then((response) => {
            let filterTarifa = response.data;

            let tipoProducto = "NORMAL"
            if(curso.tipo == "Personalizado"){
              tipoProducto = 'PERSONALIZADO'
          }

          console.log(tipoProducto)
          console.log(curso.modo)
           
            let filterCurso = filterTarifa.filter((item) => item.tipo == curso.modo.toUpperCase() && item.caracteristica == tipoProducto)[0];
            let filter24 = filterTarifa.filter((item) => item.tipo == "HORA" && item.caracteristica == "24 HORAS")[0];
            let filter20 = filterTarifa.filter((item) => item.tipo == "HORA" && item.caracteristica == "20 HORAS")[0];

            setTarifaCurso(filterCurso);
            setTarifa20(filter20)
            setTarifa24(filter24)
           
          matriculaService.getActivos(curso.id).then(res=>{
           
            let totalMatriculas = res.data
            console.log(totalMatriculas)

            if(curso.horas == 20 && curso.horas < 24){

              if(totalMatriculas <=2){
              setValorCobrar( filterCurso.valor - ( (filterCurso.valor/curso.horas) * contador * diferenciaHoras))
              formOpciones.setFieldsValue({valor_matricula:filterCurso.valor - ( (filterCurso.valor/curso.horas) * (contador * diferenciaHoras) )})
              } else{
                setValorCobrar( filterCurso.valor - ( (filterCurso.valor/curso.horas) * contador * diferenciaHoras))
                formOpciones.setFieldsValue({valor_matricula:filterCurso.valor - ( (filterCurso.valor/curso.horas) * (contador * diferenciaHoras))})
              }
            }
    
            if(curso.horas >= 24){
              setValorCobrar( filterCurso.valor - ( (filterCurso.valor/curso.horas) * contador * diferenciaHoras))
              formOpciones.setFieldsValue({valor_matricula:filterCurso.valor - ( (filterCurso.valor/curso.horas) * (contador * diferenciaHoras))})
            }
    
            setLoading(false);
          })

            
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });

        
     
        })
        
        
      }

    return (
        <Modal width={1024} visible={props.visible} onOk={handleCancel} onCancel={handleCancel} >
          {loading && (
             <div className="spin_container">
             <Spin className="spin" size="large" />
           </div>
          )}

          {!loading && (
        <Content>
            <h2>Cursos</h2>
             <Table
        columns={columns}
        dataSource={cursos}
        pagination={{ pageSize: 4 }}
      />
        </Content>
        )}

            {viewModalOpciones  && cursoSelected && clases && (
              <Modal title="Opciones de matrícula" visible={viewModalOpciones} okText="Matricular" onOk={formOpciones.submit} onCancel={cancelOpciones}>
            
            {clases && (
            <div style={{textAlign:'center', margin:'10px',color:'darkred'}}>{clases}</div>
            )}

                <Form form={formOpciones} layout='vertical' onFinish={matricular}>
                <Form.Item label="Cobrar valor del ciclo?" name='matricula' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese confirmación de Matrícula al ciclo",
                    },
                  ]}>
                  <Select onChange={validarSiCiclo}>
                    <Select.Option value="Si">Si</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                  </Select>
                </Form.Item>

                
                {viewValorMatricula && (
         <Form.Item label="Valor Matrícula" name='valor_matricula'>
          <Input />
        </Form.Item>
      )
    }
   

                <Form.Item label="Cobrar Inscripción?" name='inscripcion' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese confirmación de Inscripción",
                    },]}>
                  <Select>
                    <Select.Option value="Si">Si</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item label="Fecha de matricula" name='createdAt' rules={[
                    {
                      required: true,
                      message: "Por favor ingrese fecha matricula",
                    },]}>
                 <Input type='date'></Input>
                </Form.Item>

                {/* <Form.Item label="Valor Inscripción" name='valor_inscripcion'>
                  <Input></Input>
                </Form.Item> */}
                </Form>
              </Modal>
            )}

        </Modal>
    );
};

export default MatriculaModal;