import React, {useEffect, useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  message,
  Select,
  TimePicker
} from "antd";
import { Content } from "antd/lib/layout/layout";
import cursoService from "../../services/curso.service";
import docenteService from "../../services/docente.service";
import nivelService from "../../services/nivel.service";
import jornadaService from "../../services/jornada.service";
import periodoService from "../../services/periodo.service";
import { useAuth } from '../../context/auth.context';
import moment from 'moment'
const CursoModal = (props) => {


  const [form] = Form.useForm();
  const [jornadas, setJornadas] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [docentes, setDocentes] = useState([]);
  const [niveles, setNiveles] = useState([]);
  const [ciclos, setCiclos] = useState([]);
  const [nivelesFilter, setNivelesFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [tipos,setTipos] = useState(['Eficiencia en inglés','Bkids','Por Habilidades','Personalizado'])
  const [entrenamientos,setEntrenamientos] = useState([{ciclo:'IELTS'},{ciclo:'TOEFL'},{ciclo:'OOPT'},{ciclo:'APTIS'}])
  const [viewEntrenamiento, setViewEntrenamiento] = useState(false)
  const [viewInputNivel, setViewInputNivel] = useState(false)
  const [viewInputCiclo, setViewInputCiclo] = useState(false)

  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;

  const format = 'hh:mm a';



  useEffect(() => {
    listJornadas()
    listPeriodos()
    listDocentes()
    listNiveles()
    form.resetFields()
  }, []);

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const listDocentes = () => {
    setLoading(true);
    docenteService
      .getAll()
      .then((response) => {
        let filterDocentes = response.data;
       
        filterDocentes = response.data.filter(
          (item) => item.estado == "Activo"
        );
        setDocentes(filterDocentes);
      
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const listNiveles = () => {
    setLoading(true);
    nivelService
      .getAll()
      .then((response) => {
        let filterNiveles = response.data;
       
       
        setNiveles(filterNiveles);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const listJornadas = () => {
    setLoading(true);
    jornadaService
      .getAll()
      .then((response) => {
        let filterJornada = response.data;
       
       
        setJornadas(filterJornada);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const listPeriodos = () => {
    setLoading(true);
    periodoService
      .getAll()
      .then((response) => {
        let filterPeriodo = response.data;
      
       
        setPeriodos(filterPeriodo);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const onFinish = (values) => {
   
    let horaInicial = values.horario[0]._d
    let horaFinal = values.horario[1]._d
    const form = values;
    form.horario = [horaInicial,horaFinal]
    form.diasHorario = JSON.stringify(values.dias)
    form.horaInicial = horaInicial
    form.horaFinal = horaFinal
    console.log(form)
    props.updateLoading(true);
    form.empresa_id = empresa_id;
    form.usuario_id = usuario_id
    console.log("ENVIAR",form)
    cursoService
      .create(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListCurso();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  const filterNivel = (value) =>{
    console.log(value)
    if(value != "Personalizado"){
      setViewInputNivel(false)
      setViewInputCiclo(false)
    let nivelesFilter = niveles
    nivelesFilter = nivelesFilter.filter(item=>item.tipo == value)
    const uniqueNiveles = [...new Map(nivelesFilter.map(item => [item.nombre, item])).values()]
    setNivelesFilter(uniqueNiveles)
    form.setFieldsValue({nivel:""})
    form.setFieldsValue({ciclo:""})
    setCiclos([])
  } else{
    setViewInputNivel(true)
    setViewInputCiclo(true)
    setCiclos([])
    form.setFieldsValue({nivel:""})
    form.setFieldsValue({ciclo:""})
  }
 
  }

  const filterCiclo = (value) =>{
    form.setFieldsValue({ciclo:""})
    let ciclosFilter = niveles
    ciclosFilter = ciclosFilter.filter(item=>item.nombre == value)
    setCiclos(ciclosFilter)
  }

  const setModo = (value) =>{
    if(value=="Entrenamiento"){
      setViewEntrenamiento(true)
      form.setFieldsValue({ciclo:[]})
      form.setFieldsValue({nivel:[]})
      setCiclos(entrenamientos)
      //form.setFieldsValue({curso:[]})
      form.setFieldsValue({curso:"Entrenamiento"})
      form.setFieldsValue({nivel:"Entrenamiento"})
      setViewInputNivel(false)
      setViewInputCiclo(false)
      //setNivelesFilter([{nombre:'Entrenamiento'}])
    }
    else{
      setViewEntrenamiento(false)
      form.setFieldsValue({curso:[]})
      form.setFieldsValue({nivel:[]})
      form.setFieldsValue({ciclo:[]})
    }
  }

  const onSearch = (value) => {
    
  };

  const validarHoras = () =>{
    let fechaI;
    let fechaF;
    let horaI;
    let horaF;
    let dias = [];
    let diasHorario;
    let totalHoras = 0

    let formatDias = {}
    formatDias['Lunes']={valor:1}
    formatDias['Martes']={valor:2}
    formatDias['Miércoles']={valor:3}
    formatDias['Jueves']={valor:4}
    formatDias['Viernes']={valor:5}
    formatDias['Sábado']={valor:6}
    

    fechaI = form.getFieldValue("fechaInicial")
    fechaF = form.getFieldValue("fechaFinal")

    if(fechaI && fechaF){
    if (new Date(fechaF) < new Date(fechaI)) {
      alert( "La fecha final no puede ser mayor a la fecha de inicio");
      form.setFieldsValue({"fechaFinal":null})
    }
  }

    if(form.getFieldValue("horario")){
    horaI =  moment(form.getFieldValue("horario")[0]._d)
    horaF =   moment(form.getFieldValue("horario")[1]._d)
  }
    diasHorario = form.getFieldValue("dias")

     
     if(horaI && horaF && fechaI && fechaF){

      for(let item of diasHorario){
        dias.push(formatDias[item].valor)
    }

      let diferencia = moment(horaF).diff(horaI,'hours',true)

      

      while(fechaI<=fechaF){
      
        let day = moment(fechaI).day()
       let buscar = dias.filter(item => item == day)
       if(buscar.length>0){
        totalHoras += diferencia
       }
        
       fechaI = moment(fechaI).add(1,'days').format("YYYY-MM-DD")
      
    }

   
    form.setFieldsValue({horas:totalHoras.toFixed(2)})
   
  }


  }

  return (
    <Modal width={1024} visible={props.visible} onOk={form.submit} onCancel={handleCancel} okText="Guardar" cancelText="Cancelar" >
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 22 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>

          <Col span={8}>
              <Form.Item
                label="Curso/Entrenamiento: "
                name="modo"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Modo",
                  },
                ]}
              >
                <Select onChange={setModo}>
                
                    <Select.Option key="Curso">Curso</Select.Option>
                    <Select.Option key="Entrenamiento">Entrenamiento</Select.Option>
            
                </Select>
              </Form.Item>
            </Col>

          <Col span={8}>
              <Form.Item
                label="Docente: "
                name="docente_id"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Docente",
                  },
                ]}
              >
                <Select showSearch onSearch={onSearch}  optionFilterProp="children">
                  {docentes.map((item) => (
                    <Select.Option value={item.id} key={item.id}>{item.primerNombre} {item.segundoNombre} {item.primerApellido} {item.segundoApellido}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Idioma: "
                name="idioma"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Idioma",
                  },
                ]}
              >
                <Select>
                
                    <Select.Option value="Inglés">Inglés</Select.Option>
                    <Select.Option value="Francés">Francés</Select.Option>
            
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Tipo: "
                name="tipo"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Tipo",
                  },
                ]}
              >
                <Select>
                
                    <Select.Option value="Grupo">Grupo</Select.Option>
                    <Select.Option value="Personalizado">Personalizado</Select.Option>
            
                </Select>
              </Form.Item>
            </Col>

             
            <Col span={8}>
              <Form.Item
                label="Tipo de Curso: "
                name="curso"
                rules={[
                  {
                    required: true,
                   
                    message: "Por favor ingrese Tipo de Curso",
                  },
                ]}
              >
              <Select disabled={viewEntrenamiento} onChange={filterNivel}>
                {tipos.map((item=>{
                  return <Select.Option value={item}>{item}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>
             
             {!viewInputNivel && (
            <Col span={8}>
              <Form.Item
                label="Nivel: "
                name="nivel"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nivel",
                  },
                ]}
              >
              <Select disabled={viewEntrenamiento}  onChange={filterCiclo}>
                {nivelesFilter.map((item=>{
                  return <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>
              )}

{viewInputNivel && (
            <Col span={8}>
              <Form.Item
                label="Nivel: "
                name="nivel"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nivel",
                  },
                ]}
              >
             <Input></Input>
              </Form.Item>
            </Col>
              )}

{!viewInputCiclo && (
<Col span={8}>
              <Form.Item
                label="Ciclo: "
                name="ciclo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Ciclo",
                  },
                ]}
              >
            <Select >
                {ciclos.map((item=>{
                  return <Select.Option value={item.ciclo}>{item.ciclo}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>
)}

{viewInputCiclo && (
<Col span={8}>
              <Form.Item
                label="Ciclo: "
                name="ciclo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Ciclo",
                  },
                ]}
              >
           <Input></Input>
              </Form.Item>
            </Col>
)}
            <Col span={8}>
              <Form.Item
                label="Fecha de Inicio: "
                name="fechaInicial"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha de Inicio",
                  },
                ]}
              >
              <Input type="date" onChange={validarHoras} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Fecha Final: "
                name="fechaFinal"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha Final",
                  },
                ]}
              >
              <Input type="date"  onChange={validarHoras}  />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Jornada: "
                name="jornada"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Jornada",
                  },
                ]}
              >
              <Select>
                {jornadas.map((item=>{
                  return <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Periodo: "
                name="periodo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Periodo",
                  },
                ]}
              >
              <Select>
                {periodos.map((item=>{
                  return <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Días del curso: "
                name="dias"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese días del curso",
                  },
                ]}
              >
              <Select mode="multiple" onChange={validarHoras} >
                <Select.Option value="Lunes">Lunes</Select.Option>
                <Select.Option value="Martes">Martes</Select.Option>
                <Select.Option value="Miércoles">Miércoles</Select.Option>
                <Select.Option value="Jueves">Jueves</Select.Option>
                <Select.Option value="Viernes">Viernes</Select.Option>
                <Select.Option value="Sábado">Sábado</Select.Option>
              </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Horario: "
                name="horario"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese horario",
                  },
                ]}
              >
             <TimePicker.RangePicker format={format} minuteStep={15} onChange={validarHoras}  use12Hours placeholder={['Hora Inicio','Hora Final']} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Horas por Curso: "
                name="horas"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Horas por curso",
                  },
                ]}
              >
              <Input type="number" min={2} max={24} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Modalidad de Curso: "
                name="presentacion"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Tipo de Curso",
                  },
                ]}
              >
                <Select >
                
                    <Select.Option key="Presencial">Presencial</Select.Option>
                    <Select.Option key="Virtual">Virtual</Select.Option>
            
                </Select>
              </Form.Item>
            </Col>
           
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default CursoModal;
