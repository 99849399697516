import React, {useEffect, useState} from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Input,
  message,
  Select,
  TimePicker,
  Checkbox,
  Table
} from "antd";
import { Content } from "antd/lib/layout/layout";
import cursoService from "../../services/curso.service";
import docenteService from "../../services/docente.service";
import nivelService from "../../services/nivel.service";
import jornadaService from "../../services/jornada.service";
import periodoService from "../../services/periodo.service";
import { useAuth } from '../../context/auth.context';
import moment from 'moment'
import matriculaService from "../../services/matricula.service";
const SiguienteNivelModal = (props) => {


  const [form] = Form.useForm();
  const [jornadas, setJornadas] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [docentes, setDocentes] = useState([]);
  const [niveles, setNiveles] = useState([]);
  const [ciclos, setCiclos] = useState([]);
  const [estudiantes, setEstudiantes] = useState([]);
  const [parametros, setParametros] = useState([]);
  const [notas, setNotas] = useState([]);
  const [nivelesFilter, setNivelesFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tipos,setTipos] = useState(['Eficiencia en inglés','Bkids','Por Habilidades','Personalizado'])
  const [entrenamientos,setEntrenamientos] = useState([{ciclo:'IELTS'},{ciclo:'TOEFL'},{ciclo:'OOPT'},{ciclo:'APTIS'}])
  const [viewEntrenamiento, setViewEntrenamiento] = useState(false)
  const [viewInputNivel, setViewInputNivel] = useState(false)
  const [viewInputCiclo, setViewInputCiclo] = useState(false)

  const {user,role} = useAuth()
  const empresa_id = user.empresa_id
  const usuario_id = user.id;
  const cursoSelected = props.children
  const [checkHorario, setCheckHorario] = useState(true)
  const format = 'hh:mm A';


  const estudianteCols = [{
    title:"Nombre",
    render:(record)=><div>{formatName(record.Estudiante)}</div>
  },
{title:"Promedio",
 render:(record)=><div>{calcularPromedio(record.Estudiante.id,notas,parametros)}</div>

},
{title:"Saldo",
render:(record)=><div>{formatterPeso.format(calcularSaldo(record.Estudiante.id))}</div>
},
]

  useEffect(() => {
    listJornadas()
    listPeriodos()
    listDocentes()
    listNiveles()
    listEstudiantes(cursoSelected)
    console.log(cursoSelected)
    form.setFieldsValue(cursoSelected)
    form.setFieldsValue({dias: JSON.parse(cursoSelected.diasHorario)})
    form.setFieldsValue({horario:[moment(cursoSelected.fechaInicial +" "+ cursoSelected.horaInicial),moment(cursoSelected.fechaInicial +" "+ cursoSelected.horaFinal)]})
    form.setFieldsValue({curso:null,nivel:null,ciclo:null})
  }, []);

  const handleCancel = () => {
    props.updateVisible(false);
  };

  const listDocentes = () => {
    setLoading(true);
    docenteService
      .getAll()
      .then((response) => {
        let filterDocentes = response.data;
        console.log(response.data);
        filterDocentes = response.data.filter(
          (item) => item.estado == "Activo"
        );
        setDocentes(filterDocentes);
      
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };


  const formatterPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  })

  const calcularPromedio = (id,notas,parametros) =>{
   
    let notasEstudiante;
    //console.log(parametros)
    if(notas.length>0){
     notasEstudiante = notas.filter(item=>item.estudiante_id == id)
}
    let totalPromedio = 0
    for(let param of parametros){
      let totalSub = 0
      let totalParam = 0
      if(notas.length>0){
      if(notasEstudiante.length>0){
      for(let sub of notasEstudiante){
        if(param.id == sub.parametro_id){
        totalSub += sub.valor * (sub.SubparametroCurso.porcentaje/100)
       // console.log(sub.valor * (sub.SubparametroCurso.porcentaje/100))
      }
    }
      }
    }
      totalParam = totalSub * (param.porcentaje/100)
      totalPromedio += totalParam
    }
    
    return totalPromedio.toFixed(1)
  }

  const calcularSaldo = (id) =>{
    let filtro = estudiantes.filter(item=>item.estudiante_id == id)
    console.log("SALDO DE ESTUDIANTE", filtro)
    let saldo = 0
    let totalPago = 0
    if(filtro.length>0){
        
        for(let item of filtro){
          if(item.Obligacions.length>0){
            for(let obli of item.Obligacions){
              if(obli.estado == "Activo"){
                saldo += parseFloat(obli.valor)
               

                 if(obli.PagoObligacions.length>0){
                for(let pago of obli.PagoObligacions){
                    if(pago.Pago.estado == "Activo" || pago.Pago.estado == "Generado"){
                        totalPago += parseFloat(pago.valor)
                    }
                }
            
          }

          if(obli.PagoFavorObligacions.length>0){
            for(let pago of obli.PagoFavorObligacions){
                if(pago.PagoFavor.estado == "Activo" || pago.PagoFavor.estado == "Generado"){
                    totalPago += parseFloat(pago.valor)
                }
            }
        
      }
        }
            }
           
           
        }
        
        saldo = saldo - totalPago
      }
    }
    return saldo
  }

  const listEstudiantes = (curso) => {
    setLoading(true);
    matriculaService
      .getAprobados(curso.id)
      .then((response) => {
        let filterEstudiantes = response.data[1];
        
        filterEstudiantes = response.data[1].filter(
          (item) => item.estado != "Retirado"
        );
       
        setEstudiantes(filterEstudiantes);
        setParametros(response.data[0]);
        setNotas(response.data[2]);
       
      
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const listNiveles = () => {
    setLoading(true);
    nivelService
      .getAll()
      .then((response) => {
        let filterNiveles = response.data;
        console.log(response.data);
       
        setNiveles(filterNiveles);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const listJornadas = () => {
    setLoading(true);
    jornadaService
      .getAll()
      .then((response) => {
        let filterJornada = response.data;
        console.log(response.data);
       
        setJornadas(filterJornada);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const listPeriodos = () => {
    setLoading(true);
    periodoService
      .getAll()
      .then((response) => {
        let filterPeriodo = response.data;
        console.log(response.data);
       
        setPeriodos(filterPeriodo);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    console.log(values)
    const form = values;
    if(checkHorario){
    let horaInicial = values.horario[0]._d
    let horaFinal = values.horario[1]._d
    form.horario = [horaInicial,horaFinal]
    form.dias = values.dias
    form.horaInicial = horaInicial
    form.horaFinal = horaFinal
    form.diasHorario =  JSON.stringify(values.dias)
  }
   
   
    form.checkHorario = checkHorario
    console.log(form)
    props.updateLoading(true);
    form.empresa_id = empresa_id;
    form.usuario_id = usuario_id
    form.estudiantes = selectedRowKeys
    console.log("ENVIAR",form)
    cursoService
      .nextLevel(form)
      .then((response) => {
        console.log(response.data);
        props.updateLoading(false);
        props.updateVisible(false);
        props.updateListCurso();
        message.success("Guardado Correctamente");
      })
      .catch((e) => {
        props.updateLoading(false);
        message.error("No se ha podido guardar");
        console.log(e);
      });
  };

  const filterNivel = (value) =>{
    console.log(value)
    if(value != "Personalizado"){
      setViewInputNivel(false)
      setViewInputCiclo(false)
    let nivelesFilter = niveles
    nivelesFilter = nivelesFilter.filter(item=>item.tipo == value)
    const uniqueNiveles = [...new Map(nivelesFilter.map(item => [item.nombre, item])).values()]
    setNivelesFilter(uniqueNiveles)
    form.setFieldsValue({nivel:""})
    form.setFieldsValue({ciclo:""})
    setCiclos([])
  } else{
    setViewInputNivel(true)
    setViewInputCiclo(true)
    setCiclos([])
    form.setFieldsValue({nivel:""})
    form.setFieldsValue({ciclo:""})
  }
 
  }

  const filterCiclo = (value) =>{
    form.setFieldsValue({ciclo:""})
    let ciclosFilter = niveles
    ciclosFilter = ciclosFilter.filter(item=>item.nombre == value)
    setCiclos(ciclosFilter)
  }

  const setModo = (value) =>{
    if(value=="Entrenamiento"){
      setViewEntrenamiento(true)
      form.setFieldsValue({ciclo:[]})
      form.setFieldsValue({nivel:[]})
      setCiclos(entrenamientos)
      //form.setFieldsValue({curso:[]})
      form.setFieldsValue({curso:"Entrenamiento"})
      form.setFieldsValue({nivel:"Entrenamiento"})
      setViewInputNivel(false)
      setViewInputCiclo(false)
      //setNivelesFilter([{nombre:'Entrenamiento'}])
    }
    else{
      setViewEntrenamiento(false)
      form.setFieldsValue({curso:[]})
      form.setFieldsValue({nivel:[]})
      form.setFieldsValue({ciclo:[]})
    }
  }

  const formatName = (record)=>{
    let nombre = `${record.primerNombre} ${record.segundoNombre || ""} ${record.primerApellido} ${record.segundoApellido || ""}`
    nombre = nombre.toUpperCase()
    return nombre
  }

  const onSearch = (value) => {
    
  };

  const validarHoras = () =>{
    let fechaI;
    let fechaF;
    let horaI;
    let horaF;
    let dias = [];
    let diasHorario;
    let totalHoras = 0

    let formatDias = {}
    formatDias['Lunes']={valor:1}
    formatDias['Martes']={valor:2}
    formatDias['Miércoles']={valor:3}
    formatDias['Jueves']={valor:4}
    formatDias['Viernes']={valor:5}
    formatDias['Sábado']={valor:6}
    

    fechaI = form.getFieldValue("fechaInicial")
    fechaF = form.getFieldValue("fechaFinal")
    if(form.getFieldValue("horario")){
    horaI =  moment(form.getFieldValue("horario")[0]._d)
    horaF =   moment(form.getFieldValue("horario")[1]._d)
  }
    diasHorario = form.getFieldValue("dias")

     
     if(horaI && horaF && fechaI && fechaF){

      for(let item of diasHorario){
        dias.push(formatDias[item].valor)
    }

    let diferencia = moment(horaF).diff(horaI,'hours',true)
     

      while(fechaI<=fechaF){
      
        let day = moment(fechaI).day()
       let buscar = dias.filter(item => item == day)
       if(buscar.length>0){
       
        totalHoras += diferencia
       }
        
       fechaI = moment(fechaI).add(1,'days').format("YYYY-MM-DD")
      
    }

   
    form.setFieldsValue({horas:totalHoras.toFixed(2)})
   
  }

  }

  

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  return (
    <Modal width={1024} visible={props.visible} onOk={form.submit} onCancel={handleCancel}  okText="Crear Curso">
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 22 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>

          <Col span={8}>
              <Form.Item
                label="Curso/Entrenamiento: "
                name="modo"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Modo",
                  },
                ]}
              >
                <Select onChange={setModo}>
                
                    <Select.Option key="Curso">Curso</Select.Option>
                    <Select.Option key="Entrenamiento">Entrenamiento</Select.Option>
            
                </Select>
              </Form.Item>
            </Col>

          <Col span={8}>
              <Form.Item
                label="Docente: "
                name="docente_id"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Docente",
                  },
                ]}
              >
                <Select showSearch onSearch={onSearch}  optionFilterProp="children">
                  {docentes.map((item) => (
                    <Select.Option value={item.id} key={item.id}>{item.primerNombre} {item.segundoNombre} {item.primerApellido} {item.segundoApellido}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Idioma: "
                name="idioma"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Idioma",
                  },
                ]}
              >
                <Select>
                
                    <Select.Option value="Inglés">Inglés</Select.Option>
                    <Select.Option value="Francés">Francés</Select.Option>
            
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Tipo: "
                name="tipo"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Tipo",
                  },
                ]}
              >
                <Select>
                
                    <Select.Option value="Grupo">Grupo</Select.Option>
                    <Select.Option value="Personalizado">Personalizado</Select.Option>
            
                </Select>
              </Form.Item>
            </Col>

             
            <Col span={8}>
              <Form.Item
                label="Tipo de Curso: "
                name="curso"
                rules={[
                  {
                    required: true,
                   
                    message: "Por favor ingrese Tipo de Curso",
                  },
                ]}
              >
              <Select disabled={viewEntrenamiento} onChange={filterNivel}>
                {tipos.map((item=>{
                  return <Select.Option value={item}>{item}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>
             
             {!viewInputNivel && (
            <Col span={8}>
              <Form.Item
                label="Nivel: "
                name="nivel"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nivel",
                  },
                ]}
              >
              <Select disabled={viewEntrenamiento}  onChange={filterCiclo}>
                {nivelesFilter.map((item=>{
                  return <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>
              )}

{viewInputNivel && (
            <Col span={8}>
              <Form.Item
                label="Nivel: "
                name="nivel"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Nivel",
                  },
                ]}
              >
             <Input></Input>
              </Form.Item>
            </Col>
              )}

{!viewInputCiclo && (
<Col span={8}>
              <Form.Item
                label="Ciclo: "
                name="ciclo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Ciclo",
                  },
                ]}
              >
            <Select >
                {ciclos.map((item=>{
                  return <Select.Option value={item.ciclo}>{item.ciclo}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>
)}

{viewInputCiclo && (
<Col span={8}>
              <Form.Item
                label="Ciclo: "
                name="ciclo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Ciclo",
                  },
                ]}
              >
           <Input></Input>
              </Form.Item>
            </Col>
)}
            <Col span={8}>
              <Form.Item
                label="Fecha de Inicio: "
                name="fechaInicial"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha de Inicio",
                  },
                ]}
              >
              <Input type="date" onChange={validarHoras} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Fecha Final: "
                name="fechaFinal"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Fecha Final",
                  },
                ]}
              >
              <Input type="date" onChange={validarHoras} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Jornada: "
                name="jornada"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Jornada",
                  },
                ]}
              >
              <Select>
                {jornadas.map((item=>{
                  return <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Periodo: "
                name="periodo"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Periodo",
                  },
                ]}
              >
              <Select>
                {periodos.map((item=>{
                  return <Select.Option value={item.nombre}>{item.nombre}</Select.Option>
                }))}
              </Select>
              </Form.Item>
            </Col>

           
            

           

            <Col span={8}>
              <Form.Item
                label="Modalidad de Curso: "
                name="presentacion"
                rules={[
                  {
                    required: false,
                    message: "Por favor ingrese Tipo de Curso",
                  },
                ]}
              >
                <Select >
                
                    <Select.Option key="Presencial">Presencial</Select.Option>
                    <Select.Option key="Virtual">Virtual</Select.Option>
            
                </Select>
              </Form.Item>
            </Col>

            

{checkHorario && (
    <>
            <Col span={8}>
              <Form.Item
                label="Días del curso: "
                name="dias"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese días del curso",
                  },
                ]}
              >
              <Select mode="multiple" onChange={validarHoras}>
                <Select.Option value="Lunes">Lunes</Select.Option>
                <Select.Option value="Martes">Martes</Select.Option>
                <Select.Option value="Miércoles">Miércoles</Select.Option>
                <Select.Option value="Jueves">Jueves</Select.Option>
                <Select.Option value="Viernes">Viernes</Select.Option>
                <Select.Option value="Sábado">Sábado</Select.Option>
              </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Horario: "
                name="horario"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese horario",
                  },
                ]}
              >
             <TimePicker.RangePicker format={format}  minuteStep={15}  use12Hours placeholder={['Hora Inicio','Hora Final']} onChange={validarHoras} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Horas por Curso: "
                name="horas"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese Horas por curso",
                  },
                ]}
              >
              <Input type="number" />
              </Form.Item>
            </Col>

            <Col span={24}>
             <Table rowSelection={rowSelection} pagination={{ pageSize: 20 }} rowKey={(record) => record.Estudiante.id} dataSource={estudiantes} columns={estudianteCols}></Table>
            </Col>

            </>
           )}
           
          </Row>
        </Form>
      </Content>
    </Modal>
  );
};

export default SiguienteNivelModal;
